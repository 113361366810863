<template>
    <div class="admin_page_body">
        <div class="legend_main">
            <el-steps :active="steps" process-status="finish" finish-status="success" simple>
                <el-step title="选择商品分类" icon="el-icon-thumb"></el-step>
                <el-step title="填写商品详情" icon="el-icon-edit"></el-step>
                <el-step title="商品发布成功" icon="el-icon-circle-check"></el-step>
            </el-steps>

            <h1 class="title mt32">说明</h1>
            <p class="text"> · 完成商品发布，选择跳转页面。</p>
        </div>

        <div class="merchantEditSuccess">
            <i class="el-icon-success icon"></i>
            <div class="name" v-if="$route.query.status === '1'">商品发布成功</div>
            <div class="name" v-if="$route.query.status === '2'">商品定时发布成功</div>
            <div class="name" v-if="$route.query.status === '0'">商品放入仓库成功</div>
            <div class="desc">恭喜您，<template v-if="$route.query.status === '1'">发布商品成功</template><template v-if="$route.query.status === '2'">商品定时发布成功</template><template v-if="$route.query.status === '0'">商品放入仓库成功</template>。</div>

            <div class="group">
                <el-button type="text" size="small" @click="$router.replace({path: '/shop/detail', query: {id: $route.query.id}})">去店铺查看商品详情<i class="el-icon-d-arrow-right"></i></el-button>
                <el-button type="text" size="small" class="ml48" @click="$router.push({path: '/admin/goods/edit', query: {id: $route.query.id}})">重新编辑刚<template v-if="$route.query.status === '1'">发布</template><template v-if="$route.query.status === '2'">定时发布</template><template v-if="$route.query.status === '0'">放入仓库</template>的商品<i class="el-icon-d-arrow-right"></i></el-button>

                <div class="more">
                    <h1>你还可以做如下操作：</h1>
                    <p>1.继续 <el-button type="text" size="small" @click="$router.replace('/admin/goods/add')">"发布新商品"</el-button></p>
                    <p>2.进入"商家中心"管理 <el-button type="text" size="small" @click="$router.replace('/admin/goods/sell')">"商品列表"</el-button></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "adminGoodsAddSuccess",
  data () {
    return {
      steps: 2,
      form: {
        goodsDetail: ''
      },
      isSubmitLoading: false,
      isRequestLoading: false,
    }
  },
  created() {
    this.$store.commit('changeScrollTop');
  },
  methods: {
  },
  components: {}
}
</script>
